<template>
  <div class="dialogTitle">
    <div class="mainTitle">{{ titleMess.mainTitle }}</div>
    <div class="mallTitle">{{ titleMess.mallTitle }}</div>
  </div>
</template>
<script>
export default {
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    titleMess: {
      // eslint-disable-next-line vue/return-in-computed-property
      get() {
        return this.tit[this.index]
      }
    }
  },
  data() {
    return {
      tit: [{
        mainTitle: '公文流转系统，归类有序不紊乱',
        mallTitle: '各级委办局设计打造的专属公文流转系统，实现数字化，减少碳排放'
      }, {
        mainTitle: '系统上手简单还可定制',
        mallTitle: '适用于政府、事业单位、机关单位、医院等行业'
      }]
    }
  }
}
</script>
<style lang="less" scoped>
  .dialogTitle {
    text-align: center;
    line-height: 1;
    padding-top: 36px;
    margin-bottom: 82px;
    .mainTitle {
      font-size: 24px;
      color: #121212;
    }
    .mallTitle {
      font-size: 20px;
      color: #4B4B4B;
      margin-top: 13px;
    }
  }
</style>
